/* eslint-disable global-require */
export const intlFallback = () => {
    if (!global.Intl) {
        global.Intl = require('intl')
        require('intl/locale-data/jsonp/en-US')
    }
}

export const convertToCurrency = (num) => {
    intlFallback()
    return num === '' ? 0 : new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(num)
}

export const convertToCurrencySmall = (num) => {
    intlFallback()
    return new Intl.NumberFormat('en-US', {
        style: 'currency', currency: 'USD', notation: 'compact', compactDisplay: 'long',
    }).format(num)
}

export const updateCalculations = ({
    userPrice,
    minPrice,
    minProfit,
    itemsSold,
    ratio,
}) => {
    const userProfit = (userPrice - minPrice) + minProfit
    const userTotalProfit = itemsSold * userProfit
    const upsideQuantity = Math.round(itemsSold * ratio)
    const userUpsideProfit = upsideQuantity * userProfit

    return {
        userProfit,
        userTotalProfit,
        upsideQuantity,
        userUpsideProfit,
    }
}

export const getYouTubeId = (video) => {
    // eslint-disable-next-line no-useless-escape
    const urlRegex = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|\&v=)([^#\&\?]*).*/
    return video.match(urlRegex)[2]
}

export const storageAvailable = (type) => {
    let storage
    try {
        storage = window[type]
        const x = '__storage_test__'
        storage.setItem(x, x)
        storage.removeItem(x)
        return true
    } catch (e) {
        return e instanceof DOMException && (
            // everything except Firefox
            e.code === 22
            // Firefox
            || e.code === 1014
            // test name field too, because code might not be present
            // everything except Firefox
            || e.name === 'QuotaExceededError'
            // Firefox
            || e.name === 'NS_ERROR_DOM_QUOTA_REACHED')
            // acknowledge QuotaExceededError only if there's something already stored
            && (storage && storage.length !== 0)
    }
}

export function disableScroll() {
    // Get the current page scroll position
    const scrollTop = window.pageYOffset
    const scrollLeft = window.pageXOffset

    // if any scroll is attempted, set this to the previous value
    window.onscroll = function () {
        window.scrollTo(scrollLeft, scrollTop)
    }
}

export function enableScroll() {
    window.onscroll = function () {}
}

/* eslint-disable no-else-return */
export function detectWebGL() {
    const canvas = document.createElement('canvas')
    const gl = canvas.getContext('webgl') || canvas.getContext('experimental-webgl')
    if (gl && typeof WebGLRenderingContext === 'function') {
        return true
    } else {
        return false
    }
}

// // left: 37, up: 38, right: 39, down: 40,
// // spacebar: 32, pageup: 33, pagedown: 34, end: 35, home: 36
// var keys = {37: 1, 38: 1, 39: 1, 40: 1};

// function preventDefault(e) {
//     e.preventDefault();
// }

// function preventDefaultForScrollKeys(e) {
//   if (keys[e.keyCode]) {
//     preventDefault(e);
//     return false;
//   }
// }

// // modern Chrome requires { passive: false } when adding event
// var supportsPassive = false;
// try {
//   window.addEventListener("test", null, Object.defineProperty({}, 'passive', {
//     get: function () { supportsPassive = true; }
//   }));
// } catch(e) {}

// var wheelOpt = supportsPassive ? { passive: false } : false;
// var wheelEvent = 'onwheel' in document.createElement('div') ? 'wheel' : 'mousewheel';

// // call this to Disable
// export const disableScroll = () => {
//   window.addEventListener('DOMMouseScroll', preventDefault, false); // older FF
//   window.addEventListener(wheelEvent, preventDefault, wheelOpt); // modern desktop
//   window.addEventListener('touchmove', preventDefault, wheelOpt); // mobile
//   window.addEventListener('keydown', preventDefaultForScrollKeys, false);
// }

// // call this to Enable
// export const enableScroll = () => {
//   window.removeEventListener('DOMMouseScroll', preventDefault, false);
//   window.removeEventListener(wheelEvent, preventDefault, wheelOpt);
//   window.removeEventListener('touchmove', preventDefault, wheelOpt);
//   window.removeEventListener('keydown', preventDefaultForScrollKeys, false);
// }
