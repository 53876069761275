import getConfig from 'next/config'

const { publicRuntimeConfig: { APP_LINK } } = getConfig()

export const LANDING_PAGE_CONTENTFUL_ID = '7tTntKToxamThGSay523ej'
export const ATELIER_PAGE_CONTENTFUL_ID = 'HDkBEGFJaJY82yMWtsz3J'
export const NFT_PAGE_CONTENTFUL_ID = '6yHzSqywvFwFUpP7NAdMz9'
export const ABOUT_US_CONTENTFUL_ID = '7LcVsUJK76TIYesNpzjxrr'
export const CAREERS_CONTENTFUL_ID = '628E2pE5Yu6bAMLk2afGrf'
export const MANIFESTO_CONTENTFUL_ID = '7It0IIlnaScAUetMw5ObT9'
export const TERMS_CONTENTFUL_ID = '1tOm5tJUY0KusRcB2xEHER'
export const PRIVACY_CONTENTFUL_ID = '5We97dc7a6kKhADhIiSEj'
export const COOKIE_CONTENTFUL_ID = '1d5azN62iYyhd5YTVWINW2'
export const DEMO_CONTENTFUL_ID = '1zDrL5pu7tFRybOErLbZzq'
export const BASQUIAT_CONTENTFUL_ID = '3L26xAMBu2FOX9x8unSGus'
export const WEB3_CONTENTFUL_ID = '012SYXyP1Xkay2vNenHftk'
export const GODS_CONTENTFUL_ID = '1BrCfDPNojHSKxH9Usp7Wl'
export const FORESIGHT_CONTENTFUL_ID = '2Y4Ak5mMEmYwAAvLC95wSo'

export const PATHS = {
    HOME: '/',
    APP: '//coolab.ai',
    APP_TEAM: '//coolab.ai/team',
    COMPARE: '//coolab.ai/canva-vs-coolab',
    MANIFESTO: '/manifesto',
    ABOUT_US: '/aboutus',
    CAREERS: '/careers',
    PRIVACY_POLICY: '/privacy-policy',
    TERMS_CONDITIONS: '/terms-and-conditions',
    COOKIE_POLICY: '/cookie-policy',
    DEMO_PAGE: '/meet-stich',
    BASQUIAT_PAGE: '/meet-basquiat',
    MEMBERS: '/members',
    CONTACT_US: 'mailto:human@urbancoolab.com?subject=Join the revolution',
    CUSTOMER_CARE: 'mailto:human@urbancoolab.com?subject=Customer care',
    PARTNERSHIPS: 'mailto:human@urbancoolab.com?subject=Partnerships',
    INVESTORS: 'mailto:human@urbancoolab.com?subject=Investors',
    STICH: APP_LINK,
    REGISTER: `${APP_LINK}register`,
    STORE: '//store.urbancoolab.com/',
    COLLABS: '//store.urbancoolab.com/pages/designers',
    STORE_MEN: '//store.urbancoolab.com/collections/men',
    STORE_WOMEN: '//store.urbancoolab.com/collections/women',
    BLOG: '/blog/',
    ATELIER: '/atelier',
    FORESIGHT: '/foresight',
    MACPUNKS: '/macpunks',
    WEB3: '/web3',
    NFT: '/nft',
    HOW_IT_WORKS: 'how-it-works',
    WHAT_YOU_NEED_TO_DO: 'what-you-need-to-do',
    HOW_TO_MAKE_MONEY: 'how-to-make-money',
    HOW_TO_GET_DISCOVERED: 'how-to-get-discovered',
    NFT_TEMPLATE: '/nfttemplate',
    UTM1: `${APP_LINK}register?utm_source=members&utm_medium=webpage&utm_content=video`,
    UTM2: `${APP_LINK}register?utm_source=members&utm_medium=webpage&utm_content=price`,
    UTM3: `${APP_LINK}register?utm_source=members&utm_medium=webpage&utm_content=viewedall`,
}

export const PAGES_ID_MAP = {
    'how-it-works': '6xPOo3HFxL7u3xp1Ezyp4M',
    'what-you-need-to-do': '4lHCsFVmFv2gvh3q5Q6zKN',
    'how-to-make-money': '1WAtvKf0on0DIFxAYxQYoX',
    'how-to-get-discovered': '4hLsSalkt3LG3BazXnCb3O',
}

export const ERROR_MSGS = [
    'download more RAM',
    'boot up SKYNET',
    'recharge their batteries',
    '01001100 01001111 01001100',
    'make a trello card',
]
