import React, { useState, useEffect } from 'react'
import Link from 'next/link'
import { PATHS } from '../../utils/vars'
import { storageAvailable } from '../../utils/funcs'

import styles from './CookiePopup.module.scss'

const CookiePopup = () => {
    const [showCookie, setShowCookie] = useState(false)
    const [opacity, setOpacity] = useState(1)

    useEffect(() => {
        const sessionCookie = storageAvailable('sessionStorage')
            ? sessionStorage.getItem('ucl_cookie') : false

        setShowCookie(sessionCookie)
        window.onscroll = () => {
            const scrollTop = (window.pageYOffset !== undefined)
                ? window.pageYOffset
                : (document.documentElement
    || document.body.parentNode || document.body).scrollTop
            setOpacity(opacity - (scrollTop / 1000))

            if (scrollTop >= 1000) {
                setShowCookie(true)
            }
        }
    }, [])

    useEffect(() => {
        if (storageAvailable('sessionStorage')) {
            sessionStorage.setItem('ucl_cookie', showCookie)
        }
    }, [showCookie])

    if (!showCookie) {
        return (
            <div className={styles.cookiePopup} style={{ opacity }}>
                <p className={styles.txt}>
                    We use first and third-party cookies to
                    run the website. By continuing to use our
                    site you agree to their use. For more information,
                    check out our&nbsp;
                    <Link href={PATHS.COOKIE_POLICY}>
                        <a className={styles.cookieLink}>Cookie Policy</a>
                    </Link>
                    .
                    <button
                        type="button"
                        onClick={() => setShowCookie(true)}
                        className={styles.closePopup}
                    >
                        x
                    </button>
                </p>
            </div>
        )
    }
    return null
}

export default CookiePopup
