import TagManager from 'react-gtm-module'
import getConfig from 'next/config'

const { publicRuntimeConfig: { GTM_ID } } = getConfig()

// eslint-disable-next-line import/prefer-default-export
export const initGTM = () => {
    const tagManagerArgs = {
        gtmId: GTM_ID,
    }

    TagManager.initialize(tagManagerArgs)
}
